import React from "react"
import "jquery/dist/jquery.slim"
import Slider from "react-slick"
// import "slick-carousel/slick/slick.css"
// import "slick-carousel/slick/slick-theme.css"
import "../css/testimonial.css"

import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa"

const Quote = ({ testimonialtitle, title, subtitle, description }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  }
  return (
    <>
      <div className="container-fluid">
        <div className="row title">
          <div className="col-sm-12 headline">{testimonialtitle}</div>
        </div>

        <div className="row pb-4">
          <div className="col-md-2 text-right text-lg-right text-center">
            <div id="leftalignquote">
              <span className="leftQuote">
                <FaQuoteLeft />
              </span>
            </div>
          </div>
          <div className="col-md-8 text-center">
            <Slider className="owl-theme" {...settings}>
              <div>
                <h5>
                  <span className="testimonial-title">
                    {" "}
                    Mr. Girisha Krishnappa{" "}
                  </span>
                  <br />
                  &nbsp;&nbsp;People and Culture, AirAsia
                </h5>
                <hr className="test-border" />
                <p>
                  I truly appreciate all the support that Succeed extended in
                  regards to e-Learning on POSH, which helped us in making sure
                  that all of our workforces are trained and awareness was
                  spread so effectively within a very short time.I also wish to
                  let you know your response to every email sent out by our
                  employees were super quick and solution-oriented.I thank you
                  on behalf of all our employees for such great work and
                  support.
                </p>
              </div>

              <div>
                <h5>
                  <span className="testimonial-title"> Ms. Roja Puppala </span>
                  <br />
                  &nbsp;&nbsp;Sr. Manager, SmartDrive Systems (India) Private
                  Limited
                </h5>
                <hr className="test-border" />
                <p>
                  The PoSH Learning modules are very clear and concise and to
                  the point. Received good feedback from our leadership team on
                  the content. Extremely happy with the promptness in responding
                  to queries and resolving issues.
                </p>
              </div>

              <div>
                <h5>
                  <span className="testimonial-title">IT Director </span>
                  <br />
                  &nbsp;&nbsp;Technology Company, Silicon Valley
                </h5>
                <hr className="test-border" />
                <p>
                  Our organization has greatly benefited from the development
                  and implementation of a security awareness program provided by
                  Succeed. The customization and adaptive learning that was
                  delivered by this team was truly helpful and intuitive.
                </p>
              </div>

              <div>
                <h5>
                  <span className="testimonial-title">
                    IT Security Manager{" "}
                  </span>
                  <br />
                  &nbsp;&nbsp;Aggreko
                </h5>
                <hr className="test-border" />
                <p>
                  I was very happy with the Security Awareness training. We only
                  made some minor changes and overall the module had a good
                  balance of content and found the right level covering the
                  basics and emphasising the key messages.
                </p>
              </div>

              <div>
                <h5>
                  <span className="testimonial-title">GDPR Consultant </span>
                  <br />
                  &nbsp;&nbsp;UK & EMEA
                </h5>
                <hr className="test-border" />
                <p>
                  This course was three times better than the best course I have
                  seen in the market.
                </p>
              </div>
            </Slider>
          </div>
          <div className="col-md-2">
            <div id="rightalignquote" className="text-center text-lg-left">
              <span className="rightquote">
                <FaQuoteRight />
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Quote
